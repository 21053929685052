<template>
  <div>
    <div id="custom_card" class="card sticky">
      <div class="grid justify-content-between">
        <div class="col-12 md:col-6">
          <div
            class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
          >
            <p style="color: #6b719b; font-size: 20px; font-weight: 800">
              {{ $t('dash') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
        <!--<div class="col-12 lg:col-3 xl:col-3 zoom">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/agents')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">Total Agent</span>
                <div class=" text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ total_agent }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round "
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-user text-blue-500 text-5xl"></i>
              </div>
            </div>
          
          </div>
        </div>-->
        <div class="col-12 lg:col-3 xl:col-3 zoom" style="width: 20%; height: 100%;">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/categories')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">{{ $t('total_dept') }}</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ total_categories }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-table text-orange-500 text-5xl"></i>
              </div>
            </div>
            
          </div> 
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom" style="width: 20%; height: 100%;"> 
          <div class="card mb-0" id="custom_card_shadow"  @click="go('/tickets',1)">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">{{ $t('open_ticket') }}</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ open_tickets }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-envelope text-cyan-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom" style="width: 20%; height: 100%;"> 
          <div class="card mb-0" id="custom_card_shadow"  @click="go('/tickets',2)">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">{{ $t('InProgress_Ticket') }}</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ inprogress_tickets }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-envelope text-cyan-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom" style="width: 20%; height: 100%;">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/tickets', 3)">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">{{ $t('close_ticket') }}</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ closed_tickets  }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-lock text-purple-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
        <div class="col-12 lg:col-3 xl:col-3 zoom" style="width: 20%; height: 100%;">
          <div class="card mb-0" id="custom_card_shadow" @click="go('/ward', 'closed')">
            <div class="flex justify-content-between mb-0">
              <div>
                <span class="block text-500 font-medium mb-3">{{ $t('total_ward') }}</span>
                <div class="text-5xl" style="color: #6b719b; font-size: 20px; font-weight: 800">{{ total_ward  }}</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width: 6rem; height: 5rem"
              >
                <i class="pi pi-list text-red-500 text-5xl"></i>
              </div>
            </div>
            
          </div>
        </div>
      </div>



    <div class="grid">
      <!-- <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #8396b1"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Total Agents
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ total_agent }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-refresh text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #00b0b0"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Total Categories
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ total_categories }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-pencil text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_user_rider()">
          <div
            class="card mb-0"
            style="background-color: #a3cc88"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Open Tickets
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ open_tickets }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-blue-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-bookmark text-blue-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-3 xl:col-3 zoom">
        <div v-on:click="go_to_driver()">
          <div
            class="card mb-0"
            style="background-color: #f8c100"
            id="custom_card_shadow"
          >
            <div class="flex justify-content-between mb-1">
              <div>
                <h4
                  class="mb-3"
                  style="color: black; font-size: 20px; font-weight: 700"
                >
                  Closed Tickets
                </h4>
                <div class="text-900 font-medium text-xl">
                  <b>{{ closed_tickets }}</b>
                </div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-orange-100 border-round"
                style="width: 4rem; height: 4rem"
              >
                <i class="pi pi-fw pi-times text-orange-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      

      <div class="col-12 lg:col-12 xl:col-12">
        <div class="card" id="custom_card_shadow">
          <div class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-3 md:col-3" style="margin-top: 10px">
                <h4 style="color: #6c757d; font-size: 16px; font-weight: 700">
                  {{ $t('new_close') }}
                </h4>
              </div>
              <div  v-if="user_type !== 'Agent'" class="field col-3 md:col-3" style="margin-top: 1%">
                <span class="p-float-label">
                  <Dropdown
                    v-model="agent_selected"
                    :options="agent_dropdownItems"
                    :value="agent_dropdownItems._id"
                    optionLabel="name"
                    filter
                    @change="GetTicketByDateDashboard()"
                  >
                  </Dropdown>
                  <label for="name">{{ $t('select_emp') }}</label>
                </span>
              </div>
              <div class="field col-3 md:col-3" style="margin-top: 1%">
                <span class="p-float-label">
                  <Dropdown
                    id="Category"
                    v-model="category_selected"
                    :options="category_dropdown"
                    :value="category_dropdown"
                    optionLabel="name"
                    filter
                    @change="GetTicketByDateDashboard()"
                  ></Dropdown>
                  <label for="Category">{{ $t('select_dept') }}</label>
                </span>
              </div>
             
              <div class="field col-3 md:col-3" style="margin-top: 1%">
                <span class="p-float-label">
                  <Calendar
                    dateFormat="dd-mm-yy"
                    inputId="calendar"
                    v-model="dates"
                    selectionMode="range"
                    :manualInput="false"
                  ></Calendar>
                  <label for="calendar">{{ $t('Calendar') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-12">
                <Chart type="bar" :data="chartData" :options="chartOptions"  ref="chartComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-6">
        <div class="card" id="custom_card_shadow">
          <div class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-4 md:col-4" style="margin-top: 10px">
                <h4 style="color: #6c757d; font-size: 16px; font-weight: 700">
                  {{ $t('ticket_by') }}
                </h4>
              </div>
              <!-- <div class="field col-8 md:col-8">
                <SelectButton
                  v-model="AgentselectButtonValue"
                  :options="AgentselectButtonValues"
                  optionLabel="name"
                  class="custom-select-button"
                />
              </div> -->
              <!-- <div class="field col-8 md:col-8">
                <Dropdown
                  v-model="WardselectButtonValue"
                  :options="WardselectButtonValues"
                  optionLabel="name"
                  class="custom-dropdown"
                  placeholder="Select Ward"

                />
                
              </div> -->

              <div  class="field col-8 md:col-8" >
                <span class="p-float-label">
                  <Dropdown
                    v-model="WardselectButtonValue"
                    :options="WardselectButtonValues"
                  
                    optionLabel="name"
                    filter
                    
                  >
                  </Dropdown>
                  <label for="name">{{ $t('select_ward') }}</label>
                </span>
              </div>

              <div class="field col-12 md:col-12">
                <div class="flex-container">
                  <Chart
                    type="pie"
                    :data="wardPieData"
                    :options="pieOptions"
                    ref="wardComponent"
                    class="w-full md:w-25rem"
                  />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12"> -->
        <!-- hello -->
      <Dialog
          v-model:visible="ticketDialog"
          :header="$t('ticket_list')"
          :modal="true"

          
        >
        
        <div class="flex flex-wrap align-items-center mb-3">
      <div class="field md:col-2 mr-4">
        <span class="p-float-label">
         
          <Dropdown
          v-model="category"
          :options="category_dropdown"
          optionLabel="name"
          class="custom-dropdown"
          :placeholder="$t('select_cat')"
        />
        </span>
      </div>
      
      <div class="field md:col-2 mr-2">
        <span class="p-float-label">
          <!-- <Calendar
            dateFormat="dd-mm-yy"
            inputId="calendar"
            v-model="dates1"
            aria-placeholder="Calendar"
            selectionMode="range"
            :manualInput="false"
          ></Calendar>
          <label for="calendar">Calendar</label> -->

          <Calendar
                    dateFormat="dd-mm-yy"
                    inputId="calendar"
                    v-model="dates1"
                    selectionMode="range"
                    :manualInput="false"
                    :placeholder="$t('calender')"
                  ></Calendar>
                  
        </span> 
      </div>
   
      <div class="field md:col-2 mr-2">
        <Dropdown
          v-model="Wardselect"
          :options="WardselectButtonValues"
          optionLabel="name"
          class="custom-dropdown"
         :placeholder="$t('Select Ward')"
        /> 
      </div>
      
      <div class="field md:col-2 mr-2">
        <Dropdown
          v-model="selectButton"
          :options="selectButtonValues"
          optionLabel="name"
          class="custom-dropdown"
          :placeholder="$t('select_status')"
        />
      
      
      </div>
      <div class="field md:col-2 mr-2">
        
        <Button
      
      icon="pi pi-search"
      class="p-button-outlined ml-1"
       :style="{ backgroundColor: '#007bff', color: 'white', borderColor: '#007bff', cursor: 'pointer' }"
      @click="get_ticket_list(1)"
    /> &nbsp;&nbsp;
    
    <Button
    
      icon="pi pi-refresh"
      class="p-button-outlined p-button-secondary ml-1"
       :style="{ backgroundColor: '#FF0400', color: 'white', borderColor: '#FF0400', cursor: 'pointer' }"
      @click="resetFilters"
    />
    &nbsp;&nbsp;
    <!-- <Button
             
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            /> -->
           
      <!-- <Button  icon="pi pi-upload"  @click="exportCSV" v-tooltip.bottom="'Export'"/> -->
      <downloadexcel
              :header="$t('list_of_ticket')"
              :name="$t('ticket_list')"
              :fetch="fetchData" 
              :fields="jsonFields"
               :style="{ display: 'inline-block' }"
            >
              <Button 
                icon="pi pi-file-excel"  
                class="btn_green"
                v-tooltip.bottom="$t('excel')"
              />
      </downloadexcel>
           
        
      </div>

     
    </div>
   
       
        <DataTable
            ref="list_dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="ticket_list"
            v-model:selection="selectedProducts" 
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            
            style="font-size: 12px"
            :rowHover="true"
            showGridlines
          >
          <template #empty>
          <b style="text-align:center">No records found.</b>
        </template> 
          <!-- {{ ticket_list }} -->
          <Column :header="$t('sr_no')" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="currentPage==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(currentPage-1)}}</span>
            </template>
          </Column>
          <Column :header="$t('Date')" field="date" style="min-width: 5rem;" >
            <template #body="{ data}">
            
              <!-- {{ data.date }} -->
              {{ formatDate(data.date) }}

            </template>
          </Column>
          
          <Column :header="$t('ticket_no')" field="ticket_no"  style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.ticket_no }}

            </template>
          </Column>
          <Column :header="$t('ward_no')" field="ward_no"  style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.ward_no }}
              <!-- date ticket_no ward_no ticket_category_name title description created_by_name customer_mobile ticket_status_name -->

            </template>
          </Column>
          <Column :header="$t('Department')" field="ticket_category_name"  style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.ticket_category_name }}

            </template>
          </Column>
          
          
          <Column :header="$t('ticket_title')" field="title" style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.title }}

            </template>
          </Column>
          
          <!-- <Column header="Ticket Description" field="description" style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.description }}

            </template>
          </Column> -->
          <Column header="Customer Name" field="created_by_name" style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.created_by_name }}

            </template>
          </Column>
          <Column :header="$t('cust_mob')" field="customer_mobile" style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.customer_mobile }}

            </template>
          </Column>
          
          <Column :header="$t('status')" field="ticket_status_name" style="min-width: 5rem;text-align: center;">
            <template #body="{ data}">
            
              {{ data.ticket_status_name }}

            </template>
          </Column>
         
          <Column :header="$t('View')" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-eye"
                v-tooltip.bottom="$t('View')"
                class="p-button-rounded p-button-info mt-2 "
                @click="goToTicketDetails(slotProps.data)"
              />
              </template>
              </Column>
        </DataTable>
        
         
        </Dialog>
      <!-- </div> -->
      <div class="col-12 lg:col-6 xl:col-6">
        <div class="card" id="custom_card_shadow">
          <div class="col-12">
            <div class="p-fluid formgrid grid">
              <div class="field col-4 md:col-4" style="margin-top: 10px">
                <h4 style="color: #6c757d; font-size: 16px; font-weight: 700">
                {{ $t('ticket_by_dept') }}
                </h4>
              </div>
             
              <div class="field col-8 md:col-8">
              <Dropdown
                v-model="selectButtonValue"
                :options="selectButtonValues"
                optionLabel="name"
                class="custom-dropdown"
                :placeholder="$t('select_option')"

              />
            </div>
              
              <div class="field col-12 md:col-12">
                <div class="flex-container">
                  <Chart
                    type="pie"
                    ref="categoryComponent"
                    :data="pieData"
                    :options="categorypieOptions"
                    class="w-full md:w-25rem"
                  />
                </div>
              </div>
              
            </div>
          </div>
        </div></div>
    </div>
   
  </div>
</template>
    <script>
import EventBus from "@/AppEventBus";
 import downloadexcel from "vue-json-excel3";
import ProductService from "../service/ProductService";
import apis from "@/apis";
import axios from "axios";
import { Tabs, Tab, TabPanels, TabPanel } from "vue3-tabs";
import superset_dashboard from "../components/superset_dashboard";
import DashboardComponent from "../components/DashboardComponent";
import { ref, onMounted } from "vue";
import JsonExcel from "vue-json-excel";
const chartData = ref();
const chartOptions = ref();

const pieOptions = ref();
export default {
  
  
  data() {
    return {
      selectedProducts: null,
      filters: {},
      columns: [],
      user_type: "Agent",
      chart_name:"",
      chartData: {
        
        labels: [],
        datasets: [
          {
            type:"line",
            label: "Inprogress",
            data: [],
            fill: false,
            // borderColor: documentStyle.getPropertyValue('--p-orange-500'),
             borderColor: "#a9a9a9",
            backgroundColor:"#a9a9a9",
            tension: 0.4,
          },
          {
            type:"bar",
            label: "Open Ticket",
            data: [],
            fill: false,
            backgroundColor:"#00FFFF",
            borderColor: "#EC4899",
            tension: 0.4,
          },
          {
            type:"bar",
            label: "Inprogress Ticket",
            data: [],
            fill: false,
            backgroundColor:"#ff9000",
            borderColor: "#ff4500",
            tension: 0.4,
          },
          
          {
            type:"bar",
            label: "Closed Ticket",
            data: [],
            fill: false,
            backgroundColor:"#680000",
            borderColor: "#ff4500",
            tension: 0.4,
          }
        ],
      },
      page_no:1,
      metaFields:[],
      excelHeader:["Ticket List" ],
      jsonFields : {
        "Sr No": "sr_no",
        "Date ": "date",
        "Ticket No":"ticket_no",
        "Ward No ":"ward_no" ,
        " Department Name" : "ticket_category_name",
        "Title " : "title" ,
        "Description ":"description",
        "Created By Name":"created_by_name",
        "Mobile No":"customer_mobile",
        "Status Name":"ticket_status_name"
      },
                          
      exportData:[],
      limit:10,
      chartOptions: { aspectRatio: 3.0 },
      dates: null,
      dates1:[],
      
      status_name:"",
      from_date:"",
      ticketDialog:false,
      category :"",
      date_range:"",
       Wardselect:"",
      selectButton:"",
      category_dropdown: [],
      category_selected: "",
      category_id: "",
      agent_dropdownItems: [],
      agent_selected: "",
      agent_id: "",
      tabs: ["Dashboard"],
      selectedTab: "Dashboard",
      open_tickets: 0,
      inprogress_tickets:0,
      ticket_category_dropdown: [],
      ticket_category_selected: "",
      closed_tickets: 0,
      ticket_data:[],
      isLoadingModel: false,
      fullPage: false,
      total_categories: 0,
      total_ward:0,
      total_agent: 0,
      pieData: {},
      limit: 10,
      agentPieData: {},
      ticket_list:[],
      wardPieData:{},
      pieOptions: {},
      categorypieOptions:{},
      CategoriesData: [],
      AgentData: [],
      totalRecords:0,
      WardData:[],
      selectButtonValues: [],
      selectButtonValue: null,
      AgentselectButtonValues: [],
      
      WardselectButtonValues: [],
     
      WardselectButtonValue: null,
      AgentselectButtonValue: null,
      currentPage: 1,
      ward_no:'',
      category_name:'',
      ticket_status:''

      
    };
  },
  productService: null,
  themeChangeListener: null,
  async mounted() {
    const data = await this.DashboardData();
    this.filteredTickets = [...this.ticket_list];
  
    this.ChartData(data);
    
    // chartData.value = setChartData();
    // chartOptions.value = setChartOptions();
    this.chartOptions.onClick = (event, elements, chart) => { this.handleChartClick(event,''); };

    this.pieOptions.onClick=(event, elements, chart) => { this.handleChartClick(event,'ward'); };
    this.categorypieOptions.onClick=(event, elements, chart) => { this.handleChartClick(event,'category'); };
    
    const ticketStatus = localStorage.getItem("ticket_status");
  console.log(ticketStatus);  
 
  if (ticketStatus == 'प्रलंबित') {
    
  } else if (ticketStatus == 'बंद') {
    
  }
  },
  async created() {
    window.Buffer = window.Buffer || Buffer;
    this.productService = new ProductService();
    this.user_key = localStorage.getItem("user_key");
    
    await this.DashboardData();
    await this.CategoriesDashboard();
    await this.AgentDashboard();
    await this.getAgentMaster();
    await this.getTicketCategoryMaster();
    this.dates = await this.getCurrentMonthStartAndEnd();
    await this.GetTicketByDateDashboard();
    await this.WardDashboard();

    if (this.$i18n.locale == 'en') {
        this.jsonFields={
          
  "Sr No":"sr_no",
          "Date ": "date",
          "Ticket No":"ticket_no",
          "Ward No ":"ward_no" ,
          " Department Name" : "ticket_category_name",
          "Title " : "title" ,
          "Description ":"description",
          "Created By Name":"created_by_name",
          "Mobile No":"customer_mobile",
          "Status Name":"ticket_status_name"
        };
      } else {
        this.jsonFields={
          
          "अनु. क्र":"sr_no",
          "दिनांक ": "date",
          "तिकीट क्र":"ticket_no",
          "प्रभाग क्र ":"ward_no" ,
          "विभागाचे नाव" : "ticket_category_name",
          "शीर्षक " : "title" ,
          "वर्णन ":"description",
          "नावाने तयार केले":"created_by_name",
          "मोबाईल नंबर":"customer_mobile",
          "स्थितीचे नाव":"ticket_status_name"
};}
    
  },
  watch: {
    currentLocale(){
      // this.CategoriesDashboard(this.currentLocale);
      // this.getTicketCategoryMaster(this.currentLocale);
      this.GetTicketByDateDashboard(this.currentLocale);
      this.AgentDashboard(this.currentLocale);
      this.getTicketCategoryMaster(this.currentLocale);
      this.CategoriesDashboard(this.currentLocale);
      this.WardDashboard(this.currentLocale);
      if (this.currentLocale == 'en') {
        this.jsonFields={
          "Sr No":"sr_no",
          "Date ": "date",
          "Ticket No":"ticket_no",
          "Ward No ":"ward_no" ,
          " Department Name" : "ticket_category_name",
          "Title " : "title" ,
          "Description ":"description",
          "Created By Name":"created_by_name",
          "Mobile No":"customer_mobile",
          "Status Name":"ticket_status_name"

        };
      } else {
        this.jsonFields={
          
        "अनु. क्र":"sr_no",
          "दिनांक ": "date",
          "तिकीट क्र":"ticket_no",
          "प्रभाग क्र ":"ward_no" ,
          "विभागाचे नाव" : "ticket_category_name",
          "शीर्षक " : "title" ,
          "वर्णन ":"description",
          "नावाने तयार केले":"created_by_name",
          "मोबाईल नंबर":"customer_mobile",
          "स्थितीचे नाव":"ticket_status_name"

        };
      }
    },
    async dates() {
      this.GetTicketByDateDashboard();
    },
    async selectedTab() {
      if (this.selectedTab == "Dashboard") {
        await this.DashboardData();
        await this.CategoriesDashboard();
        await this.AgentDashboard();
        this.dates = await this.getCurrentMonthStartAndEnd();
        await this.GetTicketByDateDashboard();
      }
    },
    selectButtonValue: function (newVal, Oldval) {
      if (newVal == null) {
        newVal = Oldval;
        this.selectButtonValue = { name: newVal.name, code: newVal.code };
      }
      if (this.selectButtonValue != null && newVal.name && newVal.code) {
        for (let index = 0; index < this.CategoriesData.length; index++) {
          if (newVal.name == this.CategoriesData[index].name) {
            this.PieData(this.CategoriesData[index]);
          }
        }
      }
    },
    
    WardselectButtonValue: function (newVal, Oldval) {
      if (newVal == null) {
        newVal = Oldval;
        this.WardselectButtonValue = { name: newVal.name, code: newVal.code };
      }
      if (this.WardselectButtonValue != null && newVal.name && newVal.code) {
        for (let index = 0; index < this.WardData.length; index++) {
          if (newVal.name == this.WardData[index].name) {
            this.WardPieData(this.WardData[index]);
          }
        }
      }
    },
    AgentselectButtonValue: function (newVal, Oldval) {
      if (newVal == null) {
        newVal = Oldval;
        this.AgentselectButtonValue = { name: newVal.name, code: newVal.code };
      }
      if (this.AgentselectButtonValue != null && newVal.name && newVal.code) {
        for (let index = 0; index < this.AgentData.length; index++) {
          if (newVal.name == this.AgentData[index].name) {
            this.AgentPieData(this.AgentData[index]);
          }
        }
      }
    },
  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
    // ticket_list() {
    //   alert("here");
    //   alert(event.page);

    //   const startIndex = (this.currentPage - 1) * this.limit;
    //   const endIndex = startIndex + this.limit;
    //   return this.ticket_data.slice(startIndex, endIndex);
    // },
   
  
  },

  methods: {
    async exportCSV() {
     await this.$refs.list_dt.exportCSV();
    },
    fetchData() {
      var temp_data = [];
      for (let i = 0; i < this.ticket_list.length; i++) {
        var item = this.ticket_list[i];
        var sr_no = i + 1 + this.limit * (this.page_no - 1);
        temp_data.push({
          sr_no: sr_no,
          date : item.date,
          ticket_no: item.ticket_no,
          ward_no : item.ward_no ,
          ticket_category_name   : item.ticket_category_name,
          title : item.title ,
          description: item.description,
          created_by_name:item.created_by_name,
          customer_mobile  :item.customer_mobile,
          ticket_status_name:item.ticket_status_name
        });
      }
      return temp_data;
    },
    
    formatDate(dateString) {
    if (!dateString) return ""; 
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2); 
    return `${day}-${month}-${year}`;
  },
    downloadStatement(products){

      
this.exportData = products.map((product, index) => {
return {
  ...product, 
  sr_no: this.page_no === 1 ? index + 1 : index + 1 + this.limit * (this.page_no - 1)
};
});

this.excelHeader = [`Complaint List`];
this.metaFields = [
          {
            name: `Complaint List`,
            style: { fontWeight: "bold", fontSize: 20, marginBottom: 30 },
          },
          
        ];
const excelElement = document.getElementById("excelExport");
if (excelElement) {
  excelElement.click();
}

},
     async goToTicketDetails(data) 
    {
      var details=
            {
                ticket_status:data.ticket_status,
                ticket_no:data.ticket_no
            }
      
      await localStorage.setItem('TicketDetails','');
            await localStorage.setItem('TicketDetails',JSON.stringify(details));
            this.$router.push({name:"ticket_details"});
    },
    async resetFilters() 
    {
      this.category = "";
      this.dates1 = "";
      this.dates = " ";
      
      this.Wardselect = "";
      this.selectButton = "";
      await this.get_ticket_list(1);
    },



    async handleChartClick(event,chart_name='') {
    var chart="";
    var elements="";
    var { datasetIndex, index } = {};
    var dataset="";    
    var label="";
    var value="";
    this.chart_name=chart_name;
    this.category_name='';
    if(chart_name=='')
    {
      chart = this.$refs.chartComponent.chart; 
      
      elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      var { datasetIndex, index } = elements[0];
     
      dataset = this.chartData.datasets[datasetIndex];
      label = this.chartData.labels[index];
      value = dataset.data[index];

      
      this.from_date=label;
      this.ward_no="";
      if (this.category_selected && this.category_selected.name) {
       
        this.category  = this.category_selected;
        this.category_name=this.category_selected.name;
      }
      this.dates1 = this.dates;
      this.ticket_status=dataset.label;   
    }
    if(chart_name=='ward')
    {
      
      chart = this.$refs.wardComponent.chart; 
      
      elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      
     var { datasetIndex, index } = elements[0];
      dataset = this.wardPieData.datasets[datasetIndex];
      label = this.wardPieData.labels[index];
      value = dataset.data[index];
      
      this.category_name=label;
      this.ward_no=this.WardselectButtonValue.code;
      this.Wardselect = this.WardselectButtonValue;
      const selectedCategory = this.category_dropdown.find(
        (category) => category.name === this.category_name
      );

      if (selectedCategory) {
        this.category = {
          name: selectedCategory.name,
          _id: selectedCategory._id,
        };
      }
      this.from_date="";
      this.ticket_status="";
    }
    if(chart_name=='category')
    {
      chart = this.$refs.categoryComponent.chart; 
      
      elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      
     var { datasetIndex, index } = elements[0];
      dataset = this.pieData.datasets[datasetIndex];
      label = this.pieData.labels[index];
      value = dataset.data[index];

      this.category_name = label;
      this.ticket_status = this.selectButtonValue.name;
      const selectedCategory = this.category_dropdown.find(
        (category) => category.name === this.category_name
      );

      if (selectedCategory) {
        this.category = {
          name: selectedCategory.name,
          _id: selectedCategory._id,
        };
      }
      this.selectButton = this.selectButtonValue;
      this.ward_no="";
      this.from_date="";
      
    }
    if (elements.length > 0) {
         console.log(dataset)
        console.log(`Dataset Index: ${datasetIndex}, Data Index: ${index} Data Set: ${dataset}`);
        
        this.status_name=dataset.label;
        this.dates1="";
        await this.get_ticket_list(1);
        this.ticketDialog=true;
        console.log(`Clicked on: Label=${label}, Dataset=${dataset.label}, Value=${value}`);
    } else {
        console.log("Clicked outside of chart elements.");
    }
},
async onPage(event) {
  this.lazyParams = event;
  //alert(event.page)
  this.currentPage = event.page + 1;  // Current page (1-indexed)
  
  this.limit = event.rows;  // Number of rows per page

  // Calculate the start and end index based on the page and limit
  const startIndex = event.page * event.rows;
  const endIndex = startIndex + event.rows;

  // Slice the data correctly to avoid repetition
  this.ticket_list = this.ticket_data.slice(startIndex, endIndex);

    },
    
    go(path, ticketType)
        {
          localStorage.setItem("mis_status_group", ticketType);
            this.$router.push(path);
           
    //         if (ticketType == 'pending') {
    //   localStorage.setItem("ticket_status", '65a4f5156177e69880ece5c9');  
    // } else if (ticketType == 'closed') {
    //   localStorage.setItem("ticket_status", '65a4f59d6177e69880ece5cb');  
    // }

            
        },
        dateFormatYMD(inputDate) {
  if (!inputDate) return "";
  const parsedDate = new Date(inputDate);
  if (isNaN(parsedDate)) return inputDate;
  const date = String(parsedDate.getDate()).padStart(2, '0');
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
  const year = parsedDate.getFullYear();
  return `${year}-${month}-${date}`;
},
    // dateFormatYMD(inputDate) {
    //   var postdate = inputDate;
    //   if (inputDate) {
    //     inputDate = new Date(inputDate);
    //     if (inputDate.toString() == "NaN-NaN-NaN") {
    //       return postdate;
    //     } else {
    //       let date = ("0" + inputDate.getDate()).slice(-2);
    //       // current month
    //       let month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    //       // current year
    //       let year = inputDate.getFullYear();
    //       if (date.toString() == "aN") {
    //         return postdate;
    //       } else {
    //         return year + "-" + month + "-" + date;
    //       }
    //     }
    //   } else {
    //     return "";
    //   }
    // },
    async getCurrentMonthStartAndEnd() {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      // Format dates as strings in the specified format
      const startOfMonthString = startOfMonth.toISOString();
      const endOfMonthString = endOfMonth.toISOString();
      return [new Date(startOfMonthString), new Date(endOfMonthString)];
    },
    async getAgentMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      var promise = apis.agentListDropdown(data);
      promise.then((response) => {
        var temp = [];
        for (let index = 0; index < response.data.data.length; index++) {
          temp.push({
            _id: response.data.data[index].agent_id,
            name: response.data.data[index].agent_name,
          });
        }
        this.agent_dropdownItems = temp;
      });
    },
    async getTicketCategoryMaster() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      };
      this.isLoadingModel = true;
      var promise = apis.TicketCategoryMaster(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.category_dropdown = response.data.data.map(item => ({
          name: item.name,
          _id: item._id
        }));

      });
    },
    
    async DashboardData() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
      };
      this.isLoadingModel = true;
      var promise = apis.AdminDashboardData(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        var temp = response.data.data;
        this.total_categories = response.data.total_categories ? response.data.total_categories : 0;
       this.total_ward = response.data.total_ward ? response.data.total_ward :0;
        this.total_agent = response.data.total_agents
          ? response.data.total_agents
          : 0;
        for (let index = 0; index < temp.length; index++) {
          const element = temp[index];
          //console.log("data",element);
          //pending
          if (element._id == 1) {
            this.open_tickets = element.total;
          
          }
          //inprogress
          if (element._id == 2) {
            this.inprogress_tickets = element.total;
          }
          //closed
          if (element._id == 3) {
            this.closed_tickets = element.total;
           
          }
        }
      });
    },
    async CategoriesDashboard() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      };
      this.isLoadingModel = true;
      var promise = apis.ticketByCategoriesDashboard(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.CategoriesData = response.data.data;
                // this.selectButtonValues=[];
                this.selectButtonValues=[];

        for (let index = 0; index < this.CategoriesData.length; index++) {
          this.selectButtonValues.push({
            name: this.CategoriesData[index].name,
            code: this.CategoriesData[index]._id,
          });
        }
        this.selectButtonValue = {
          name: this.CategoriesData[0].name,
          code: this.CategoriesData[0]._id,
        };
        this.PieData(this.CategoriesData[0]);
      });
    },
      async get_ticket_list(flag) {
        this.loading = true;
        
        var promise ='';
        
        if (flag==1) 
        {
          var from_date='';
          var to_date='';
          if(this.dates1.length>0)
          {
            from_date = this.dateFormatYMD(this.dates1[0]);
            to_date = this.dateFormatYMD(this.dates1[1]);
          
          }
          var cat ="";
          // console.log("cat",this.category)
          // console.log("cat1",this.category._id)
          if(this.category && this.category._id)
          { 
            cat = this.category._id;
          }
          //console.log("cat2",this.category.name)
          if (this.category_name) 
          {
            for (let index = 0; index < this.category_dropdown.length; index++) 
            {
              if (this.category_dropdown[index]['name']==this.category_name) 
              {
                cat = this.category_dropdown[index]['_id'];
                //console.log("cat3",cat);
              }
            }
            this.category_name="";
          }

          var status ="";
          if (this.selectButton) 
          {
            status = this.selectButton.code;
          }
          var wardno="";
           if (this.Wardselect && this.Wardselect.name) {
            wardno = this.Wardselect.code;
           }
           else if (this.ward_no) {
            wardno = this.ward_no;
            
           }
          if(this.chart_name ==='')
          {
            var data = 
              {
                limit: this.limit, 
                page_no: this.page_no, 
                ward_no: wardno, 
                status:status, 
                category: cat, 
                customer_id: this.customer_id,
                from_date: from_date, 
                to_date: to_date, 
                count: false,     
              };
              console.log("data",data);
              promise= apis.ticketlist(data, this.id);
            }
            else{
              var data = {
                user_id: localStorage.getItem("id"),
                status_name:this.status_name,
                date: this.from_date,
                category:cat,
                ward_no:wardno,
                status_name:this.ticket_status
                
                };
                promise=apis.dashboardticketlist(data,this.id);
                    
            }
        } else 
        {
          var data = {
          user_id: localStorage.getItem("id"),
          status_name:this.status_name,
          date: this.from_date,
          category_name:this.category_name,
          ward_no:this.ward_no,
          status_name:this.ticket_status
          
          };
          promise=apis.dashboardticketlist(data,this.id);
          
        }
        
        // var promise = apis.ticketlist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          this.ticket_list = response.data.data;
          this.ticket_data=this.ticket_list;
          this.totalRecords=this.ticket_list.length;
      
        
        });
      },
    async AgentDashboard() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      };
      this.isLoadingModel = true;
      var promise = apis.ticketByAgentAdminDashboard(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.AgentData = response.data.message;
        // for (let index = 0; index < this.AgentData.length; index++) {
        //   this.AgentselectButtonValues.push({
        //     name: this.AgentData[index].name,
        //     code: this.AgentData[index].sequence_number,
        //   });
        // }
        var  distinctLabels = [...new Set(response.data.message.flatMap(status => status.label))];
        this.AgentselectButtonValues=[];
        for (let index = 0; index < distinctLabels.length; index++) {
          this.AgentselectButtonValues.push({
            name: distinctLabels[index],
            code: distinctLabels[index],
          });
        }
        this.AgentselectButtonValue = {
          name: this.AgentData[0].name,
          code: this.AgentData[0].sequence_number,
        };
        this.AgentPieData(this.AgentData[0]);
      });
    },
    async WardDashboard() {
      var data = {
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        lang:this.$i18n.locale
      };
      this.isLoadingModel = true;
      var promise = apis.ticketByWardAdminDashboard(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        this.WardData = response.data.data;
        //console.log("warddata",this.WardData);
        this.WardselectButtonValues=[];
        for (let index = 0; index < this.WardData.length; index++) {
          this.WardselectButtonValues.push({
            name: this.WardData[index].name,
            code: this.WardData[index].ward_no,
          });
        }
        this.WardselectButtonValue = {
          name: this.WardData[0].name,
          code: this.WardData[0].ward_no,
        };
        this.WardPieData(this.WardData[0]);
      });
    },
    async GetTicketByDateDashboard() {
      this.chartData = null;
      //get category id
      this.category_id = this.category_selected._id;
      // get agent type
      this.agent_id = this.agent_selected._id;
      var from_date = this.dateFormatYMD(this.dates[0]);
      var to_date = this.dateFormatYMD(this.dates[1]);
      var data = {
        from_date: from_date,
        to_date: to_date,
        client_id: "65a4f82f6177e69880ece5d6",
        user_id: localStorage.getItem("id"),
        agent_id: this.agent_id,
        category_id: this.category_id,
        lang:this.$i18n.locale
      };
      var promise = apis.ticketByDateDashboard(data);
      promise.then((response) => {
        var data = response.data.data;
        this.ChartData(data);
        console.log(data);
      });
    },
    async ChartData(data) {

      const documentStyle = getComputedStyle(document.documentElement);
      this.chartData = data
    },
    async PieData(data) {
      console.log(data);
      this.pieData = {
        labels: data.labels,
        datasets: [
          {
            data: data.series,
            backgroundColor: [
              "#F04136",
              "#2B3890",
              "#F7941E",
              "#39B54A",
              "#D7F422",
              "#DE6BBC",
              "#DEDD59",
              "#58DEDF",
            ],
            // hoverBackgroundColor: [
            //   "#FF6384",
            //   "#36A2EB",
            //   "#FFCE56",
            //   "#662D91",
            //   "#F8F194",
            //   "#58DEDF",
            //   "#949597",
            //   "#DEDD59",
            // ],
          },
        ],
      };
    },
    async AgentPieData(data) {
      this.agentPieData = {
        labels: data.label,
        datasets: [
          {
            data: data.series,
            backgroundColor: [
              "#F04136",
              "#2B3890",
              "#F7941E",
              "#39B54A",
              "#D7F422",
              "#DE6BBC",
              "#DEDD59",
              "#58DEDF",
            ],
            // hoverBackgroundColor: [
            //   "#FF6384",
            //   "#36A2EB",
            //   "#FFCE56",
            //   "#662D91",
            //   "#F8F194",
            //   "#58DEDF",
            //   "#949597",
            //   "#DEDD59",
            // ],
          },
        ],
      };
    },
    async WardPieData(data) {
      this.wardPieData = {
        labels: data.labels,
        datasets: [
          {
            data: data.series,
            backgroundColor: [
             "#662D91",
              "#F04136",
              "#2B3890",
              "#F7941E",
              "#39B54A",
              "#D7F422",
              "#DE6BBC",
              "#DEDD59",
              "#58DEDF",
            ],
            // hoverBackgroundColor: [
            //   "#FF6384",
            //   "#36A2EB",
            //   "#FFCE56",
            //   "#662D91",
            //   "#F8F194",
            //   "#58DEDF",
            //   "#949597",
            //   "#DEDD59",
            // ],
          },
        ],
      };
    },
  },
  components: {
    Tabs,
    Tab,
    TabPanels,
    superset_dashboard,
    DashboardComponent,
    downloadexcel,
    JsonExcel
  },
};
</script>
    <style>
</style>
    <style scoped>
#mapContainer {
  width: 100%;
  height: 100vh;
}
.p-button-sm {
  font-size: 0.7rem;
  padding: 0.4rem 0.8rem;
}
.active {
  border-bottom: 4px solid #274461;
  color: black;
  font-weight: bold;
}
.tab:hover {
  font-weight: bold;
  border-bottom: 4px solid #274461;
}
.inactive {
  color: black;
}
.card {
  cursor: pointer;
}
#custom_card {
  border-top: 3px solid #6b719b;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
#custom_card_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}
.flex-container {
  display: flex;
  justify-content: center;
}
.flex-container > div {
  text-align: center;
}
</style>